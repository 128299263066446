import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { memoize } from 'lodash-es';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IntlMessagesProps, UserTranslations, UserLang } from './IntlMessages.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './IntlMessages.module.scss';

export const getTooltip = memoize((key: string): string | null => {
  const lang = localStorage.getItem('lang');
  const translations = localStorage.getItem('translations');

  const userLang: UserLang | null = lang
    ? JSON.parse(lang)
    : null;

  const userTranslations: UserTranslations | null = translations
    ? JSON.parse(translations)
    : null;

  if (!userLang || !userTranslations) return null;

  const tooltipLang = userTranslations[`${userLang.locale}Tooltip`];
  return tooltipLang ? tooltipLang[key] : null;
});

const IntlMessages: React.FC<IntlMessagesProps> = ({ id }) => {
  const tooltipMessage = getTooltip(id);

  if (tooltipMessage) {
    return (
      <Tooltip
        id={id}
        title={tooltipMessage}
        classes={{ tooltip: styles.tooltip }}
        interactive
        arrow
      >
        <span>
          <FormattedMessage id={id} />
          <FontAwesomeIcon
            className='mx-2'
            icon={faInfoCircle as IconProp}
            style={{ fontSize: '.8em' }}
          />
        </span>
      </Tooltip>
    );
  }

  return <FormattedMessage id={id} />;
};

IntlMessages.defaultProps = {
  id: 'translation.missing',
};

export default React.memo(IntlMessages);
